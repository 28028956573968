import React from "react"
import {Link} from "gatsby"
import ThemeChanger from "../components/themeChanger"

export default (props) => (
  <ul className="navbar-list">
    <li class="navbar-item">
      <Link to="/" className="navbar-link">About</Link>
    </li>
    <li class="navbar-item">
      <Link to="/resume" className="navbar-link">Resume</Link>
    </li>
    <li class="navbar-item">
      <Link to="/portfolio" className="navbar-link">Portfolio</Link>
    </li>
    <li class="navbar-item">
      <Link to="/blog" className="navbar-link">Blog</Link>
    </li>
    <li class="navbar-item">
      <Link to="/contact" className="navbar-link">Contact</Link>
    </li>
    <li class="navbar-item">
      <ThemeChanger/>
    </li>
  </ul>
  
)
